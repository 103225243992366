<template>
  <component is="div">
    <b-modal
      id="modal-toc"
      ok-title="Submit"
      centered
      size="lg"
      :title="`View Previous Education — ${student.myIpId} ${student.fullName}`"
      ref="modal"
      :visible="isViewCreditOpen"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
      <b-row>
        <b-col sm="12" md="4">
          <b-form-group label="Actions" label-for="accepted-at">
            <b-button variant="primary" size="sm" block @click="openEnrollmentPrevEdu()">
              <feather-icon icon="EditIcon" size="12" />
              <span class="ml-25 align-middle">Edit RoPE</span>
            </b-button>
          </b-form-group>
          <b-form-group label="Secondary Education" label-for="secondary-education">
            <b-form-input readonly v-model="metadata.secondaryEducation"></b-form-input>
            <!--              <v-select v-model="tocEdit.section" :options="tocSectionOptions" :clearable="false" />-->
          </b-form-group>
          <b-form-group label="Terms Accept" label-for="accepted-at">
            <b-form-input readonly v-model="metadata.acceptAt"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="8">
          <b-form-group label="Previous Training" label-for="previous-training">
            <b-form-textarea no-resize readonly v-model="metadata.previousTraining" rows="3" max-rows="8"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table striped hover :items="schoolItems"></b-table>
        </b-col>

        <!--          <b-col cols="12">-->
        <!--            <b-form-group v-if="isAssign" label="ToC Notes" label-for="toc-notes">-->
        <!--              <b-form-input-->
        <!--                v-if="tocEdit.noteCustom"-->
        <!--                id="toc-notes"-->
        <!--                v-model="tocEdit.note"-->
        <!--                autofocus-->
        <!--                :state="(isAssign && tocEdit.note.length > 0) || !isAssign"-->
        <!--                trim-->
        <!--                placeholder=""-->
        <!--              />-->
        <!--              <v-select v-else v-model="tocEdit.note" :options="tocJustificationOptions" :clearable="false" />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
      </b-row>
    </b-modal>
  </component>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BRow,
  BTable,
} from 'bootstrap-vue';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import vSelect from 'vue-select';

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BDropdownItem,
    BDropdown,
    BCard,
    vSelect,
  },
  data() {
    return {
      tocEdit: {
        enabled: false,
        isModalOpen: false,
        isAssign: true,

        profile: undefined,
        section: undefined,
        note: '',
        noteCustom: false,
      },
    };
  },
  props: {
    isViewCreditOpen: {
      type: Boolean,
    },
    student: {
      type: Object,
      default: () => {},
    },
    profile: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['refresh', 'close', 'update:is-view-credit-open'],
  watch: {},
  methods: {
    closeCredit() {
      this.tocEdit.isModalOpen = false;
      this.$emit('update:is-view-credit-open', false);
    },
    openEnrollmentPrevEdu() {
      const email = this.profile.metadata.enrollmentEmail || this.student.email;
      window.open(`https://enroll.intellectualpoint.com/enrolls/${this.profile.metadata.enrollmentId}/credit?email=${email}`);
    },
  },
  computed: {
    metadata() {
      return this.profile.metadata.enrollmentToc || {};
    },
    schoolItems() {
      return this.metadata.schools.map((school) => {
        // return school;
        return {
          Name: school.name,
          Graduated: school.graduated ? 'Yes' : 'No',
          Degree: school.degree,
          Diploma: school.diploma,
          Start: school.attend_start ? moment(school.attend_start).format('MM/YYYY') : 'n/a',
          End: school.attend_end ? moment(school.attend_end).format('MM/YYYY') : 'n/a',
        };
      });
    },
  },
  mounted() {},
  setup({ props }) {
    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant, resolvePathwayCohortVisibilityVariant } = usePathwayCohorts();

    const { resolveCourseSectionName } = useCourseSections();

    const toast = useToast();

    return {
      toast,
      title,
      moment,
      avatarText,
      resolvePathwayCohortName,
      resolveCourseSectionName,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortVisibilityVariant,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
