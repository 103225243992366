import moment from 'moment-timezone';

export const defaultTimeZone = 'America/Toronto';

export const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export const getDefaultTimeZone = () => {
  let overrideTimeZone = null;
  try {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      // overrideTimeZone = 'America/Chicago';
      if (userInfo.metadata && userInfo.metadata.timezone) {
        // overrideTimeZone = userInfo.metadata.timezone;
      }
    }
  } catch (error) {
    console.error('User does not have operations timezone set', error);
  }
  if (overrideTimeZone) {
    return overrideTimeZone;
    console.log('overrideTimeZone:', overrideTimeZone);
  }
  return moment.tz.guess() || defaultTimeZone;
};

export const momentTz = (timestamp, timeZone = getDefaultTimeZone()) => {
  return moment(timestamp).tz(timeZone);
};

export const momentTzFormatted = (timestamp, format, timeZone = getDefaultTimeZone()) => {
  // Define the mappings for timezone abbreviations to their 2-letter codes and full names
  const timeZoneMapping = {
    EST: 'ET',
    EDT: 'ET',
    CST: 'CT',
    CDT: 'CT',
    MST: 'MT',
    MDT: 'MT',
    PST: 'PT',
    PDT: 'PT',
    AST: 'AT',
    ADT: 'AT',
    NST: 'NT',
    NDT: 'NT',
  };

  const timeZoneFullNames = {
    EST: 'Eastern Time',
    EDT: 'Eastern Time',
    CST: 'Central Time',
    CDT: 'Central Time',
    MST: 'Mountain Time',
    MDT: 'Mountain Time',
    PST: 'Pacific Time',
    PDT: 'Pacific Time',
    AST: 'Atlantic Time',
    ADT: 'Atlantic Time',
    NST: 'Newfoundland Time',
    NDT: 'Newfoundland Time',
  };

  // Split the format string into parts and tag each with whether it's a custom format
  const taggedParts = format
    .split(/(zS|zF)/)
    .filter((part) => part !== '') // Filter out empty strings
    .map((part) => ({
      text: part,
      isCustomFormat: part === 'zS' || part === 'zF',
    }));

  // Use Moment to format the timestamp with the provided timezone
  const momentTime = moment.tz(timestamp, timeZone);
  const tzAbbreviation = momentTime.format('z');

  // Process each part based on whether it's a custom format
  const formattedParts = taggedParts.map(({ text, isCustomFormat }) => {
    if (isCustomFormat) {
      if (text === 'zS') {
        return timeZoneMapping[tzAbbreviation] || tzAbbreviation;
      } else if (text === 'zF') {
        return timeZoneFullNames[tzAbbreviation] || tzAbbreviation;
      }
    } else {
      return momentTime.format(text);
    }
  });

  // Join the formatted parts back into a single string
  const formattedTime = formattedParts.join('');

  return formattedTime;
};

export const calculateTimeDifference = (startTime, endTime, simple = false) => {
  const difference = endTime - startTime;
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);

  let readableTime = '';

  if (simple) {
    if (days > 0) {
      readableTime = `${days} Day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      readableTime = `${hours} Hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      readableTime = `${minutes} Minute${minutes > 1 ? 's' : ''}`;
    } else {
      readableTime = 'Now';
    }
  } else {
    if (days > 0) readableTime += `${days} Day${days > 1 ? 's' : ''}`;
    if (hours > 0) readableTime += `, ${hours} Hour${hours > 1 ? 's' : ''}`;
    if (minutes > 0) readableTime += `, ${minutes} Minute${minutes > 1 ? 's' : ''}`;
    readableTime = readableTime.trimStart() || 'Now';
  }

  return readableTime;
};
