<template>
  <div>
    <div>
      <div v-for="(note, index) in loadedNotes" class="card card-bg p-75">
        <div class="d-flex align-items-center justify-content-between mb-50">
          <div class="d-flex align-items-center text-white">
            <b-avatar size="24" :src="note.author.avatar" :text="avatarText(note.author.name)" variant="light-info" class="mr-50" />
            <span class="mr-50">{{ note.author.name }}</span>
            <span class="text-muted">{{ resolveNoteTimeAgo(note.createdAt) }}</span>
          </div>
          <div class="note-actions">
            <a class="text-muted" @click="note.edit = !note.edit"><i class="feather icon-edit"></i></a>
            <a class="text-muted" @click="deleteNote(index)"><i class="feather icon-trash"></i></a>
          </div>
        </div>

        <div style="padding-left: 2px; padding-right: 2px">
          <div v-if="note.edit">
            <b-form-textarea
              class="mb-50"
              :id="`note-description-${index}`"
              type="text"
              placeholder="Leave a note..."
              rows="3"
              max-rows="10"
              v-model="note.description"
            />

            <span class="d-flex float-right">
              <b-button variant="primary" class="btn-sm" @click="note.edit = !note.edit">
                <feather-icon icon="XCircleIcon" class="mr-25" />
                <span>Cancel</span>
              </b-button>
              <b-button variant="primary" class="btn-sm" @click="saveNotes">
                <feather-icon icon="SaveIcon" class="mr-25" />
                <span>Save Changes</span>
              </b-button>
            </span>
          </div>

          <h6 class="mb-0" v-else>{{ note.description }}</h6>
        </div>

        <div v-if="!note.edit" class="note-time text-muted mt-50">
          <small>Created: {{ momentTz(note.createdAt).format('L hh:mm A') }}</small>
          <small>•</small>
          <small v-if="note.updatedAt">Updated: {{ momentTz(note.updatedAt).format('L hh:mm A') }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
  BAvatar,
  BMedia,
  VBTooltipPlugin,
  BModal,
  BCardText,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import studentStoreModule from '../../../../../store/student/studentStoreModule';
import StudentEditTabNotes from '@/views/apps/student/students-edit/tabs/StudentEditTabNotes.vue';
import { momentTz, calculateTimeDifference } from '@/libs/utils/time';

export default {
  name: 'ProfileNoteItem',
  components: {
    StudentEditTabNotes,
    BButton,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
    BAvatar,
    BMedia,
    BModal,
    BCardText,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
  computed: {},
  data: () => {
    return {
      fields: [
        {
          key: 'title',
          label: 'Title',
          thStyle: { width: '30%' },
        },
        {
          key: 'description',
          label: 'Description',
          thStyle: { width: '50%' },
        },
        {
          key: 'createdAt',
          label: 'Created',
          thStyle: { width: '10%' },
        },
      ],
      loadedNotes: [],
      openEditModal: false,
      selectedNote: null,
    };
  },
  mounted() {
    this.initNotes();
  },
  watch: {
    profileData: {
      handler() {
        this.initNotes();
      },
      deep: true,
    },
  },
  methods: {
    closeModalImmediate() {
      this.openEditModal = false;
    },
    closeModal() {
      this.$refs.crudEditNotes.doSaveChanges();
    },
    deleteNote(index) {
      const notes = this.loadedNotes;
      const note = notes[index];

      if (note === undefined) return;

      this.$swal({
        title: 'Are you sure?',
        text: 'This will permanently delete this note',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        confirmButtonText: 'Delete',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadedNotes.splice(index, 1);

          const parsed = this.loadedNotes
            .filter((adr) => adr.title && adr.description)
            .map((note) => {
              return {
                title: note.title || 'DEPRECATED',
                description: note.description,
                visibility: note.visibility,
                author: note.author.id || note.author || this.userData.id || undefined,
                createdAt: note.createdAt,
                updatedAt: note.updatedAt,
                _id: note._id,
              };
            });

          store
            .dispatch('studentStoreModule/updateStudentProfile', {
              id: this.studentData.id,
              profileData: { notes: parsed },
            })
            .then((response) => {
              this.$emit('refetch-data');

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Note deleted!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to edit student',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
      // notes.splice(index, 1);

      console.log(notes);
    },
    saveNotes() {
      const pwd = this.studentData;
      const id = pwd.id;

      const parsed = this.loadedNotes
        .filter((note) => note.description)
        .map((note) => {
          return {
            title: note.title || 'DEPRECATED',
            description: note.description,
            visibility: 'internal',
            author: (note.author && note.author.id ? note.author.id : undefined) || note.author || this.userData.id || undefined,
            _id: note._id,
            createdAt: note.createdAt,
            updatedAt: note.updatedAt ?? new Date(),
          };
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      store
        .dispatch('studentStoreModule/updateStudentProfile', {
          id,
          profileData: { notes: parsed },
        })
        .then((response) => {
          this.$emit('refetch-data');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    initNotes() {
      if (!this.profileData.notes) return;
      this.loadedNotes = this.profileData.notes.map((note) => {
        return {
          ...note,
          edit: false,
        };
      });
    },
  },
  setup(props) {
    const resolveNoteTimeAgo = (time) => {
      const now = Date.now();
      const posted = new Date(time).getTime();
      const diff = calculateTimeDifference(posted, now, true).toLowerCase();
      return diff === 'now' ? 'just now' : diff + ' ago';
    };

    return {
      avatarText,
      resolveNoteTimeAgo,
      momentTz,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
.title-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}
</style>

<style lang="scss" scoped>
.card {
  transition: all 0.3s ease-in-out;
}
.note-actions {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  opacity: 0;
  transition: opacity 0.1s;
}
.note-time {
  display: none;
  line-height: 1rem;
  column-gap: 0.6rem;
  transition: all 0.3s ease-in-out;
}
.card:hover {
  .note-actions {
    opacity: 1;
  }
  .note-time {
    display: flex;
  }
}
</style>
