<template>
  <div class="container content-p">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="studentData === undefined">
      <h4 class="alert-heading">Error fetching student data</h4>
      <div class="alert-body">
        No student found with this student id. Check
        <b-link class="alert-link" :to="{ name: 'apps-students-list' }"> Student List </b-link>
        for other students.
      </div>
    </b-alert>

    <div v-if="studentData" :key="studentData.id">
      <b-row class="mb-25">
        <b-col cols="12" md="12">
          <student-view-header :student-data.sync="studentData" :profile-data.sync="profileData" @refetch-data="loadStudent" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="12">
          <student-view-relations :student-data="studentData" class="mb-2" @focus-document="(event) => focusProfileDocuments(event)" />
        </b-col>

        <b-col cols="12" lg="8">
          <student-view-documents @focus="() => (focusProfile = null)" :focus-profile.sync="focusProfile" :student-data="studentData" />
        </b-col>
        <b-col cols="12" lg="4">
          <student-view-notes @refetch-data="loadStudent" :student-data.sync="studentData" :profile-data.sync="profileData" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import studentStoreModule from '../../../../store/student/studentStoreModule';
import StudentViewHeader from './StudentViewHeader.vue';
import StudentViewDocuments from './StudentViewDocuments.vue';
import StudentViewNotes from './StudentViewNotes.vue';
import StudentViewRelations from './StudentViewRelations.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    StudentViewHeader,
    StudentViewDocuments,
    StudentViewNotes,
    StudentViewRelations,
  },
  watch: {
    '$route.path'() {
      this.loadStudent();
    },
  },
  data() {
    return {
      focusProfile: null,
    };
  },
  methods: {
    focusProfileDocuments(event) {
      console.log('> focusProfileDocuments: ', event);
      this.focusProfile = event.profileId;
    },
  },
  setup() {
    const studentData = ref({ metadata: {} });
    const profileData = ref({ attributes: {}, metadata: {} });

    const loadStudent = async () => {
      console.log('> refreshing from student view');

      store
        .dispatch('studentStoreModule/fetchStudent', { id: router.currentRoute.params.id })
        .then((response) => {
          studentData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            studentData.value = undefined;
          }
        });

      store
        .dispatch('studentStoreModule/fetchStudentProfile', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'notes.author' },
        })
        .then((response) => {
          profileData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            profileData.value = undefined;
          }
        });
    };

    loadStudent();

    return {
      studentData,
      profileData,
      loadStudent,
    };
  },
};
</script>

<style></style>
