import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import store from '@/store';
import { momentTzFormatted, calculateTimeDifference } from '@/libs/utils/time';

export default function usePathwayCohorts(_pathwayData) {
  // Use toast
  const toast = useToast();

  const pathwayData = ref(_pathwayData);

  const refPathwayCohortListTable = ref(null);

  // Table Handlers
  const tableColumnsList = [
    { key: 'startDate', sortable: true },
    {
      key: 'sections',
      label: 'Sections',
      formatter: (value) => {
        if (value.length === 0) return false;

        const listOfSections = value.map((item) => ({
          id: item.id,
          name: item.name,
          course: item.course,
          startDate: item.startDate,
          endDate: item.endDate,
        }));

        listOfSections.sort((a, b) => {
          if (!a.startDate) return 1;
          if (!b.startDate) return -1;
          return new Date(a.startDate) - new Date(b.startDate);
        });

        return listOfSections;
      },
    },
    {
      key: 'profiles',
      label: 'Enrolled Students',
      formatter: (value) => {
        return value.length;
      },
    },

    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];

  const tableColumnsPathwayView = [
    { key: 'startDate', label: 'Dates', sortable: true },
    {
      key: 'profiles',
      label: 'Enrolled Students',
      formatter: (value) => {
        return value.length;
      },
    },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];

  const perPage = ref(10);
  const totalPathwayCohorts = ref(0);
  const totalPageCount = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('startDate');
  const isSortDirDesc = ref(true);
  const isLoaded = ref(false);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);
  const typeFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refPathwayCohortListTable.value ? refPathwayCohortListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPathwayCohorts.value,
    };
  });

  const refetchData = () => {
    if (refPathwayCohortListTable.value) {
      refPathwayCohortListTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, sortBy, isSortDirDesc, pathwayData, typeFilter], () => {
    refetchData();
  });

  const fetchPathwayCohorts = (ctx, callback) => {
    const pw = pathwayData.value;
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = sortBy.value !== '' ? `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}` : '_id:desc';

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      pathway: pw ? pw.id : undefined,

      populate: 'sections,sections.course,pathway',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch(`rosterStoreModule/fetchPathwayCohortList${typeFilter.value ?? ''}`, pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        isLoaded.value = true;
        totalPathwayCohorts.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sessions list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const getOrdinal = (n) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const resolvePathwayCohortDateMessage = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'No Cohort Dates Specified';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      const diff = calculateTimeDifference(now, start, true);
      return `Cohort Starts ${diff === 'now' ? 'Any Moment Now' : 'In ' + diff}`;
    } else if (now > end) {
      return 'Cohort Ended';
    } else {
      const diff = calculateTimeDifference(now, end, true);
      return `Cohort Ends ${diff === 'now' ? 'Any Moment Now' : 'In ' + diff}`;
    }
  };

  const resolvePathwayCohortDateMessageVariant = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'text-danger';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      return 'text-warning';
    } else if (now > end) {
      return 'text-muted';
    } else {
      return 'text-success';
    }
  };

  const resolvePathwayCohortDateMessageIcon = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'AlertCircleIcon';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      return 'PlayCircleIcon';
    } else if (now > end) {
      return 'CheckCircleIcon';
    } else {
      return 'ClockIcon';
    }
  };

  const resolvePathwayCohortName = (pathwayCohort) => {
    if (pathwayCohort.nickname) return pathwayCohort.nickname;
    if (!pathwayCohort.startDate || !pathwayCohort.endDate) return 'Pathway Cohort';
    const startName = momentTzFormatted(pathwayCohort.startDate, 'MMM Do YYYY');
    const endName = momentTzFormatted(pathwayCohort.endDate, 'MMM Do YYYY');
    return `${startName} - ${endName}`;
  };

  const resolvePathwayCohortDateFormat = (date, format) => {
    return momentTzFormatted(date, format);
  };

  const resolvePathwayCohortVisibilityVariant = (status) => {
    if (status === 'internal') return 'warning';
    if (status === 'public') return 'success';
    return 'info';
  };

  const resolvePathwayCohortVisibilityIcon = (visibility) => {
    if (visibility === 'internal') return 'EyeOffIcon';
    if (visibility === 'public') return 'EyeIcon';
    return '';
  };

  const resolvePathwayCohortStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'enrolling') return 'info';
    if (status === 'waiting') return 'warning';
    if (status === 'live') return 'success';
    if (status === 'completed') return 'secondary';
    if (status === 'archived') return 'danger';
    return 'info';
  };

  const resolvePathwayCohortStatusIcon = (status) => {
    if (status === 'created') return '';
    if (status === 'enrolling') return 'TrendingUpIcon';
    if (status === 'waiting') return 'AlertCircleIcon';
    if (status === 'live') return 'GlobeIcon';
    if (status === 'completed') return 'CheckCircleIcon';
    if (status === 'archived') return 'TrashIcon';
    return '';
  };

  const statusOptions = [
    { label: 'Draft', value: 'draft' },
    { label: 'Created', value: 'created' },
    { label: 'Enrolling', value: 'enrolling' },
    { label: 'Waiting', value: 'waiting' },
    { label: 'Live', value: 'live' },
    { label: 'Completed', value: 'completed' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  return {
    fetchPathwayCohorts,
    tableColumnsList,
    tableColumnsPathwayView,
    perPage,
    currentPage,
    totalPathwayCohorts,
    totalPageCount,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPathwayCohortListTable,
    isLoaded,

    resolvePathwayCohortName,
    resolvePathwayCohortDateFormat,
    resolvePathwayCohortVisibilityVariant,
    resolvePathwayCohortStatusIcon,
    resolvePathwayCohortStatusVariant,
    resolvePathwayCohortVisibilityIcon,
    resolvePathwayCohortDateMessage,
    resolvePathwayCohortDateMessageVariant,
    resolvePathwayCohortDateMessageIcon,
    refetchData,

    statusOptions,
    visibilityOptions,

    typeFilter,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
