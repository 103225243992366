import { render, staticRenderFns } from "./StudentEditTabNotes.vue?vue&type=template&id=400cefb0&scoped=true&"
import script from "./StudentEditTabNotes.vue?vue&type=script&lang=js&"
export * from "./StudentEditTabNotes.vue?vue&type=script&lang=js&"
import style0 from "./StudentEditTabNotes.vue?vue&type=style&index=0&id=400cefb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400cefb0",
  null
  
)

export default component.exports