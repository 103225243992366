<template>
  <div>
    <student-blacklist
      @refetch-data="$emit('refetch-data')"
      :is-student-blacklist-open.sync="isStudentBlacklistOpen"
      :student-data.sync="studentData"
      :profile-data.sync="profileData"
    ></student-blacklist>
    <student-merger :is-student-merger-open.sync="isStudentMergerOpen" :student-data.sync="studentData"></student-merger>

    <div class="card card-bg" @contextmenu.prevent="$refs.studentViewEditMenu.open">
      <div class="card-body rounded z-2 position-relative">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-4">
            <b-avatar
              :src="studentData.avatar"
              :text="avatarText(studentData.name)"
              size="80px"
              variant="light-info"
              style="margin-top: 1px"
              class="mr-1"
            />
            <div class="d-flex flex-column align-items-start justify-content-center">
              <b-link class="d-flex align-items-center text-white">
                <small class="" style="font-size: 1.15rem; line-height: 1.35rem; margin-bottom: 1px"> {{ studentData.myIpId }} </small>
              </b-link>

              <div class="d-flex align-items-center mb-25">
                <h4
                  class="font-weight-bolder tracking-tighter mr-50"
                  style="font-size: 1.85rem; line-height: 2.15rem; margin-bottom: 2px"
                  @click="copyElementToClipboard('fullName', 'name')"
                >
                  {{ studentData.fullName }}
                </h4>
                <feather-icon
                  v-if="$can('student.update')"
                  icon="EditIcon"
                  size="13"
                  class="cursor-pointer text-muted"
                  style="margin-bottom: 2px"
                  @click="$router.push({ name: 'apps-students-edit', params: { id: studentData.id } })"
                />
              </div>

              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <b-badge class="profile-badge mr-25" :variant="`${resolveStudentStatusVariant(studentData.status)}`">
                    <feather-icon :icon="resolveStudentStatusIcon(studentData.status)" size="12" />
                    {{ title(studentData.status) }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>
          <table class="mt-0">
            <tr @click="copyElementToClipboard('email', 'email')">
              <th class="pb-50">
                <feather-icon icon="MailIcon" class="mr-75" />
                <span class="font-weight-bold">Email</span>
              </th>
              <td class="pb-50 pl-2" style="color: #d0d2d6 !important">
                {{ studentData.email }}
              </td>
            </tr>
            <tr @click="copyElementToClipboard('phoneNumber', 'phone number')">
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td class="pb-50 pl-2 text-capitalize" style="color: #d0d2d6 !important">
                {{ studentData.phoneNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 pl-2 text-capitalize" style="color: #d0d2d6 !important">
                {{ studentData.status }}
              </td>
            </tr>
            <tr>
              <th class="pb-0">
                <feather-icon icon="ShieldIcon" class="mr-75" />
                <span class="font-weight-bold">Onboarded?</span>
              </th>
              <td class="pb-0 pl-2 text-capitalize">
                <b-badge pill class="profile-badge mr-25" :variant="profileData.attributes.onboardingCompleted ? 'success' : 'danger'">
                  {{ profileData.attributes.onboardingCompleted ? 'Yes' : 'No' }}
                </b-badge>
              </td>
            </tr>
          </table>
          <table class="mt-0">
            <tr>
              <th class="pb-50">
                <feather-icon icon="ShieldIcon" class="mr-75" />
                <span class="font-weight-bold">MyIP</span>
              </th>
              <td class="pb-50 pl-2 text-capitalize">
                <b-badge pill class="profile-badge mr-25" :variant="!!studentData.metadata.myIpUserId ? 'success' : 'danger'">
                  {{ !!studentData.metadata.myIpUserId ? 'Yes' : 'No' }}
                </b-badge>
              </td>
            </tr>
            <tr>
              <th class="pb-0">
                <feather-icon icon="BookIcon" class="mr-75" />
                <span class="font-weight-bold">Learn</span>
              </th>
              <td class="pb-0 pl-2 text-capitalize">
                <b-badge pill class="profile-badge mr-25" :variant="!!studentData.metadata.learnId ? 'success' : 'danger'">
                  {{ !!studentData.metadata.learnId ? 'Yes' : 'No' }}
                </b-badge>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <vue-context ref="studentViewEditMenu" style="min-width: 11rem">
      <li>
        <b-link
          v-if="$can('student.update')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-students-edit', params: { id: studentData.id } })"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </b-link>
        <b-link v-if="$can('student.merge')" class="d-flex align-items-center justify-content-between" @click="startStudentMerge">
          <span>Merge</span>
          <feather-icon icon="GitMergeIcon" size="15" class="ml-50 text-muted" />
        </b-link>
        <b-link
          class="d-flex align-items-center justify-content-between"
          v-if="$can('student.blacklist.remove') && profileData.blacklist && profileData.blacklist.enabled"
          @click="startStudentUnBlacklist"
        >
          <span>Un-Blacklist</span>
          <font-awesome-icon icon="fa-regular fa-face-smile-halo" class="ml-50 text-muted" width="14" />
        </b-link>
        <b-link
          class="d-flex align-items-center justify-content-between"
          v-if="$can('student.blacklist.add') && ((profileData.blacklist && !profileData.blacklist.enabled) || !profileData.blacklist)"
          @click="startStudentBlacklist"
        >
          <span>Blacklist</span>
          <font-awesome-icon icon="fa-regular fa-face-swear" class="ml-50 text-muted" width="14" />
        </b-link>
        <b-link class="d-flex align-items-center justify-content-between" @click="startSendLogin">
          <span>Send Login</span>
          <feather-icon icon="LogInIcon" size="15" class="ml-50 text-muted" />
        </b-link>
        <b-link class="d-flex align-items-center justify-content-between" @click="startResetPassword">
          <span>Reset Password</span>
          <feather-icon icon="UnlockIcon" size="15" class="ml-50 text-muted" />
        </b-link>

        <b-link class="d-flex align-items-center justify-content-between" @click="sendAthenaLogin">
          <span>Send Athena</span>
          <feather-icon icon="UnlockIcon" size="15" class="ml-50 text-muted" />
        </b-link>

        <b-link class="d-flex align-items-center justify-content-between" @click="startSendOnboarding">
          <span>Send Onboarding</span>
          <feather-icon icon="SendIcon" size="15" class="ml-50 text-muted" />
        </b-link>
      </li>
    </vue-context>

    <div
      v-if="profileData.blacklist && profileData.blacklist.enabled"
      :style="`${calculateBorderColor('#ea5455')} padding: 0.075rem`"
      class="mb-1 rounded position-relative overflow-hidden drop-shadow-default"
    >
      <div class="rounded" style="background: #191a22">
        <div class="card-body rounded z-2 position-relative" :style="calculateBackgroundColor('#ea5455')">
          <b-col md="12" lg="12">
            <div>
              <span class="d-flex align-items-center mb-25">
                <feather-icon icon="AlertTriangleIcon" size="24" class="mr-50 text-warning" />
                <h3 class="font-weight-bolder mb-0">Blacklisted</h3>
              </span>

              <p class="mb-0 text-light">
                Blacklisted since {{ momentTz(profileData.blacklist.timestamp).format('L') }} for the following reason:
              </p>

              <div>
                {{ profileData.blacklist.reason || 'No reason specified' }}
              </div>
            </div>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BLink, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BDropdownItemButton, BRow } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import StudentMerger from '@/views/apps/student/students-view/components/StudentMerger';
import moment from 'moment';
import StudentBlacklist from './components/StudentBlacklist';
import { hexToRGB } from '@/libs/utils/color';
import VueContext from 'vue-context';
import useStudents from '../useStudents';
import { title } from '@core/utils/filter';
import { momentTz } from '@/libs/utils/time';

export default {
  components: {
    StudentMerger,
    StudentBlacklist,
    BBadge,
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BDropdownItem,
    BDropdownItemButton,
    BDropdown,
    VueContext,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      studentColor: '#5b5b5e',
      isStudentBlacklistOpen: false,
      isStudentMergerOpen: false,
    };
  },
  metaInfo() {
    const title = this.studentData.fullName ? `${this.studentData.fullName}` : 'View Student';
    return {
      title,
    };
  },
  methods: {
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(190deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
    startResetPassword() {
      this.$swal({
        title: 'Are you sure?',
        text: 'An email will be sent to the student, requiring them to reset thier password.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reset Password',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch('studentStoreModule/resetStudentMyIPPassword', {
              studentId: this.studentData.id,
            })
            .then(() => {
              this.$emit('refetch-data');

              this.$swal({
                icon: 'success',
                title: 'Password Reset!',
                text: 'This student has been sent an email to reset their password',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    startSendLogin() {
      this.$swal({
        title: 'Are you sure?',
        text: 'An email will be sent to the student, giving them a magic link to login.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Send Login',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch('studentStoreModule/sendStudentMyIPLogin', {
              studentId: this.studentData.id,
            })
            .then(() => {
              this.$emit('refetch-data');

              this.$swal({
                icon: 'success',
                title: 'Login Sent!',
                text: 'This student has been sent an email to login',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    startSendOnboarding() {
      this.$swal({
        title: 'Are you sure?',
        text: 'An email will be sent to the student, giving them a magic link to onboard.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Send Onboarding',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch('studentStoreModule/sendStudentMyIPOnboarding', {
              studentId: this.studentData.id,
            })
            .then(() => {
              this.$emit('refetch-data');

              this.$swal({
                icon: 'success',
                title: 'Login Sent!',
                text: 'This student has been sent an email to onboard',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    sendAthenaLogin() {
      this.$swal({
        title: 'Are you sure?',
        text: 'An email will be sent to the student, giving them a magic link to Athena.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Send Athena',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch('studentStoreModule/sendStudentAthenaLogin', {
              studentId: this.studentData.id,
            })
            .then(() => {
              this.$emit('refetch-data');

              this.$swal({
                icon: 'success',
                title: 'Login Sent!',
                text: 'This student has been sent an email to access athena',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            })
            .catch((error) => {
              if (error.response) {
                const { response } = error;

                this.$swal({
                  icon: 'error',
                  title: 'Failed to send login!',
                  text: `Sending fail: ${response.data.message}`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
            });
        }
      });
    },

    startStudentBlacklist() {
      this.isStudentBlacklistOpen = true;
    },
    startStudentUnBlacklist() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to unblacklist this student?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Forgive Them',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch('studentStoreModule/updateStudentProfile', {
              id: this.studentData.id,
              profileData: {
                blacklist: { enabled: false },
              },
            })
            .then(() => {
              this.$emit('refetch-data');

              this.$swal({
                icon: 'success',
                title: 'Un-Blacklisted!',
                text: 'This student has been removed from the blacklist',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    copyElementToClipboard(id, name) {
      this.$copyText(this.studentData[id]).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Copied ${name} to clipboard`,
              icon: 'ClipboardIcon',
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not copy!',
              icon: 'AlertCircleIcon',
            },
          });
        },
      );
    },
    startStudentMerge() {
      this.isStudentMergerOpen = true;
    },
  },
  setup() {
    const { resolveStudentStatusVariant, resolveStudentStatusIcon } = useStudents();

    return {
      resolveStudentStatusVariant,
      resolveStudentStatusIcon,
      avatarText,
      hexToRGB,
      momentTz,
      title,
    };
  },
};
</script>

<style></style>
