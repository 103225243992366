import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refAuditListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'actor', sortable: true, stickyColumn: true, variant: 'primary' },
    { key: 'label', sortable: true },
    { key: 'description', sortable: true },
    { key: 'permission', sortable: true },
    { key: 'permission', sortable: true },
    { key: 'ipAddress', sortable: false },
    { key: 'device', sortable: false },
    { key: 'deviceId', sortable: false },
    { key: 'createdAt', sortable: true, label: 'Timestamp' },
    // { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalEntries = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAuditListTable.value ? refAuditListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEntries.value,
    };
  });

  const refetchData = () => {
    refAuditListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const fetchAuditLog = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    store
      .dispatch('auditStoreModule/fetchAuditLog', {
        search: searchQuery.value,
        limit: perPage.value,
        // sortDesc: isSortDirDesc.value,
        page: currentPage.value,
        sortBy: sb,

        populate: 'actor',
        hydrated: true,

        status: statusFilter.value,
      })
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalEntries.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching audit log',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAuditLog,
    tableColumns,
    perPage,
    currentPage,
    totalEntries,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAuditListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
