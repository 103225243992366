import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import store from '@/store';
import { momentTzFormatted, calculateTimeDifference } from '@/libs/utils/time';

export default function useCourseSections(_courseData) {
  // Use toast
  const toast = useToast();

  const courseData = ref(_courseData);

  const refCourseListTable = ref(null);

  // Table Handlers
  const tableColumnsList = [
    { key: 'startDate', sortable: true },
    { key: 'instructor', sortable: true },
    // { key: 'dates', label: 'Class Dates' },
    {
      key: 'profiles',
      label: 'Enrolled Students',
      formatter: (value) => {
        return value.length;
      },
    },

    {
      key: 'direct_profiles',
      label: 'Direct Students',
      formatter: (value, key, item) => {
        if (item.courseCohort) {
          return item.courseCohort.profiles.length;
        }
        return 0;
      },
    },
    {
      key: 'classes',
      label: 'Classes',
      formatter: (value, key, item) => {
        if (item.classes) {
          return item.classes.length;
        }
        return 0;
      },
    },
    // { key: 'locations', label: 'Locations', },
    // {
    //   key: 'createdAt',
    //   label: 'Created At',
    //   formatter: (value) => {
    //     return moment(value).format('L');
    //   },
    //   sortable: true,
    // },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const tableColumnsCourseView = [
    { key: 'startDate', label: 'Dates', sortable: true },
    { key: 'instructor', sortable: true },
    {
      key: 'profiles',
      label: 'Enrolled Students',
      formatter: (value) => {
        return value.length;
      },
    },
    {
      key: 'direct_profiles',
      label: 'Direct Students',
      formatter: (value, key, item) => {
        if (item.courseCohort) {
          return item.courseCohort.profiles.length;
        }
        return 0;
      },
    },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalCourseSections = ref(0);
  const totalPageCount = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('startDate');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);
  const typeFilter = ref(null);
  const displayFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourseSections.value,
    };
  });

  const refetchData = () => {
    refCourseListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, typeFilter, courseData, isSortDirDesc, displayFilter], () => {
    refetchData();
  });

  const fetchCourseSections = (ctx, callback) => {
    const cs = courseData.value;
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = sortBy.value !== '' ? `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}` : '_id:desc';

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      course: cs ? cs.id : undefined,

      populate: 'classes,instructor,courseCohort,course',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    if (displayFilter.value) pl.display = displayFilter.value;

    store
      .dispatch(`rosterStoreModule/fetchCourseSectionList${typeFilter.value ?? ''}`, pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalCourseSections.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCourseSectionDateMessage = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'No Section Dates Specified';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      const diff = calculateTimeDifference(now, start, true);
      return `Section Starts ${diff === 'now' ? 'Any Moment Now' : 'In ' + diff}`;
    } else if (now > end) {
      return 'Section Ended';
    } else {
      const diff = calculateTimeDifference(now, end, true);
      return `Section Ends ${diff === 'now' ? 'Any Moment Now' : 'In ' + diff}`;
    }
  };

  const resolveCourseSectionDateMessageVariant = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'text-danger';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      return 'text-warning';
    } else if (now > end) {
      return 'text-muted';
    } else {
      return 'text-success';
    }
  };

  const resolveCourseSectionDateMessageIcon = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'AlertCircleIcon';
    }

    const now = Date.now();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (now < start) {
      return 'PlayCircleIcon';
    } else if (now > end) {
      return 'CheckCircleIcon';
    } else {
      return 'ClockIcon';
    }
  };

  const resolveUserRoleVariant = (role) => {
    if (role === 'student') return 'info';
    if (role === 'staff') return 'warning';
    if (role === 'proctor') return 'info';
    if (role === 'accountant') return 'success';
    if (role === 'admin' || role === 'manager') return 'danger';
    return 'info';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'student') return 'UserIcon';
    if (role === 'staff') return 'SettingsIcon';
    if (role === 'proctor') return 'BookIcon';
    if (role === 'accountant') return 'DollarSignIcon';
    if (role === 'manager') return 'Edit2Icon';
    if (role === 'admin') return 'DatabaseIcon';
    return 'UserIcon';
  };

  const resolveCourseSectionName = (courseSection) => {
    if (courseSection.nickname) return courseSection.nickname;
    if (!courseSection.startDate || !courseSection.endDate) return 'Course Section';
    const startName = momentTzFormatted(courseSection.startDate, 'MMM Do YYYY');
    const endName = momentTzFormatted(courseSection.endDate, 'MMM Do YYYY');
    return `${startName} - ${endName}`;
  };

  const resolveCourseSectionVisibilityVariant = (status) => {
    if (status === 'internal') return 'warning';
    if (status === 'public') return 'success';
    return 'info';
  };

  const resolveCourseSectionVisibilityIcon = (visibility) => {
    if (visibility === 'internal') return 'EyeOffIcon';
    if (visibility === 'public') return 'EyeIcon';
    return '';
  };

  const resolveCourseSectionStatusVariant = (status) => {
    if (status === 'draft') return 'warning';
    if (status === 'created') return 'secondary';
    if (status === 'enrolling') return 'info';
    if (status === 'waiting') return 'warning';
    if (status === 'live') return 'success';
    if (status === 'completed') return 'secondary';
    if (status === 'archived') return 'danger';
    return 'info';
  };

  const resolveCourseSectionStatusIcon = (status) => {
    if (status === 'draft') return 'AlertTriangleIcon';
    if (status === 'created') return '';
    if (status === 'enrolling') return 'TrendingUpIcon';
    if (status === 'waiting') return 'AlertCircleIcon';
    if (status === 'live') return 'GlobeIcon';
    if (status === 'completed') return 'CheckCircleIcon';
    if (status === 'archived') return 'TrashIcon';
    return '';
  };

  const resolveCourseSectionDateFormat = (date, format) => {
    return momentTzFormatted(date, format);
  };

  const statusOptions = [
    { label: 'Draft', value: 'draft' },
    { label: 'Created', value: 'created' },
    { label: 'Enrolling', value: 'enrolling' },
    { label: 'Waiting', value: 'waiting' },
    { label: 'Live', value: 'live' },
    { label: 'Completed', value: 'completed' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  return {
    fetchCourseSections,
    tableColumnsList,
    tableColumnsCourseView,
    perPage,
    currentPage,
    totalCourseSections,
    totalPageCount,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveCourseSectionDateFormat,
    resolveCourseSectionVisibilityVariant,
    resolveCourseSectionVisibilityIcon,
    resolveCourseSectionStatusVariant,
    resolveCourseSectionStatusIcon,
    resolveCourseSectionName,
    resolveCourseSectionDateMessage,
    resolveCourseSectionDateMessageVariant,
    resolveCourseSectionDateMessageIcon,
    refetchData,

    statusOptions,
    visibilityOptions,

    typeFilter,

    // Extra Filters
    visibilityFilter,
    statusFilter,
    displayFilter,
  };
}
