import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useCourses() {
  // Use toast
  const toast = useToast();

  const refCourseListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'live_sections',
      label: 'Live',
      formatter: (value, key, items) => {
        return items.sectionCounts.live;
      },
    },
    {
      key: 'completed_sections',
      label: 'Completed',
      formatter: (value, key, items) => {
        return items.sectionCounts.completed;
      },
    },
    // {
    //   key: 'createdAt',
    //   label: 'Created At',
    //   formatter: (value) => {
    //     return moment(value).format('L');
    //   },
    //   sortable: true,
    // },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalCourses = ref(0);
  const currentPage = ref(1);
  const totalPageCount = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourses.value,
    };
  });

  const refetchData = () => {
    refCourseListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter], () => {
    refetchData();
  });

  const fetchCourseList = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      calculateSectionCount: true,
      populate: '',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('rosterStoreModule/fetchCourseList', pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalCourses.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching subjects list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // archived, internal, public

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'info';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'info';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'SubjectIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'SubjectIcon';
  };

  const resolveCourseVisibilityVariant = (visibility) => {
    if (visibility === 'internal') return 'warning';
    if (visibility === 'public') return 'info';
    return 'info';
  };

  const resolveCourseVisibilityIcon = (visibility) => {
    if (visibility === 'internal') return 'EyeOffIcon';
    if (visibility === 'public') return 'EyeIcon';
    return '';
  };

  const resolveCourseStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'pending') return 'warning';
    if (status === 'live') return 'success';
    if (status === 'retired') return 'danger';
    return 'info';
  };

  const resolveCourseStatusIcon = (status) => {
    if (status === 'created') return '';
    if (status === 'pending') return 'ClockIcon';
    if (status === 'live') return 'GlobeIcon';
    if (status === 'retired') return 'TrashIcon';
    return '';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Live', value: 'live' },
    { label: 'Retired', value: 'retired' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  return {
    fetchCourseList,
    tableColumns,
    perPage,
    currentPage,
    totalPageCount,
    totalCourses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveCourseVisibilityVariant,
    resolveCourseVisibilityIcon,
    resolveCourseStatusIcon,
    resolveCourseStatusVariant,
    refetchData,

    visibilityOptions,
    statusOptions,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
