import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import store from '@/store';
import useProfiles from '@/views/apps/roster/profile/useProfiles';

export default function useAccountsList() {
  // Use toast
  const toast = useToast();

  const refAccountListTable = ref(null);

  // Table Handlers
  const tableColumnsList = [
    {
      key: 'student.fullName',
      label: 'Name',
      sortable: true,
      stickyColumn: true,
      variant: 'primary',
      tdClass: 'sticky-primary',
      thClass: 'sticky-primary',
    },
    { key: 'cohort', label: 'Cohort', sortable: true },
    { key: 'total', label: 'Debit', sortable: true },
    { key: 'totalPaid', label: 'Credit', sortable: true },
    { key: 'balance', label: 'Balance', sortable: true },
    { key: 'profile.attributes.fundingSource', label: 'Funding', sortable: true },
    { key: 'status', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'actions' },
  ];

  // Table Handlers
  const tableColumnsStudentView = [
    // { key: 'student.fullName', label: 'Name', sortable: true },
    { key: 'cohort', label: 'Cohort', sortable: true },
    { key: 'status', sortable: true },
    { key: 'total', label: 'Debit', sortable: true },
    { key: 'totalPaid', label: 'Credit', sortable: true },
    { key: 'balance', label: 'Balance', sortable: true },
    { key: 'profile.attributes.fundingSource', label: 'Funding', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'actions' },
  ];

  const {
    search: qSearch,
    status: qStatus,
    funding: qFunding,

    perPage: qPerPage,
    page: qCurrentPage,
    sortBy: qSortBy,
    sortDesc: qSortDesc,

    student: qStudentId,

    paymentDate: qPaymentDate,
  } = router.currentRoute.query;

  const perPage = ref(qPerPage || 10);
  const totalAccounts = ref(0);
  const currentPage = ref(qCurrentPage || 1);
  const totalPageCount = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref(qSearch);
  const sortBy = ref(qSortBy || 'createdAt');
  const isSortDirDesc = ref(qSortDesc || true);

  const statusFilter = ref(qStatus);
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);
  const fundingFilter = ref(qFunding);
  const balanceFilter = ref(null);

  const studentFilter = ref(qStudentId);

  const paymentDateFilter = ref(qPaymentDate ? qPaymentDate.split(',') : []);

  const dataMeta = computed(() => {
    const localItemsCount = refAccountListTable.value ? refAccountListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAccounts.value,
    };
  });

  const refetchData = () => {
    if (refAccountListTable.value) refAccountListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      statusFilter,
      createAtStartFilter,
      createAtEndFilter,
      fundingFilter,
      sortBy,
      isSortDirDesc,

      paymentDateFilter,
    ],
    () => refetchData(),
  );

  const fetchAccounts = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      populate: 'student,profile,cohort cohort.session',
    };

    if (paymentDateFilter.value) {
      console.log('applying pmt date filter', paymentDateFilter.value);
      const [start, end] = paymentDateFilter.value;
      if (start) pl.paymentStartDate = moment(start).format('YYYY-MM-DD');
      if (end) pl.paymentEndDate = moment(end).format('YYYY-MM-DD');
    }

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    if (fundingFilter.value) {
      pl.fundingSource = fundingFilter.value;
    }

    if (studentFilter.value) {
      pl.student = studentFilter.value;
    }

    /*
    resumeFilter,
    coeFilter,
    submissionFilter, */

    store
      .dispatch('receivableStoreModule/fetchAccounts', pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalAccounts.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching accounts list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAccountProgramVariant = (status) => {
    if (status === 'open') return 'success';
    if (status === 'vettec') return 'info';
    if (status === 'vrrap') return 'info';
    if (status === 'wioa') return 'warning';
    return 'secondary';
  };

  const resolveAccountYNVariant = (status) => {
    if (status === 'yes') return 'success';
    if (status === 'no') return 'danger';
    return 'warning';
  };

  const resolveAccountStatusVariant = (status) => {
    if (status === 'unpaid') return 'danger';
    if (status === 'partially_paid') return 'warning';
    if (status === 'payment_plan') return 'info';
    if (status === 'paid') return 'success';
    return 'info';
  };

  const statusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Partially Paid', value: 'partially_paid' },
    { label: 'Paid', value: 'paid' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Void', value: 'void' },
    { label: 'Archive', value: 'archived' },
  ];

  const paymentTypeOptions = [
    { label: 'Tuition', value: 'tuition' },
    { label: 'Exam Voucher', value: 'exam_voucher' },
    { label: 'eLearning Bundle', value: 'elearning_bundle' },
    { label: 'Career Services', value: 'career_services' },
    { label: 'Misc', value: 'misc' },
  ];

  const paymentStatusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Paid', value: 'paid' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Reversed', value: 'reversed' },
    { label: 'Void', value: 'void' },
  ];

  const paymentMethodOptions = [
    { label: 'Credit Card', value: 'credit_card' },
    { label: 'PayPal', value: 'paypal' },
    { label: 'Check', value: 'check' },
    { label: 'Cash', value: 'cash' },
    { label: 'ACH/EFT', value: 'ach' },
    { label: 'Purchase Order', value: 'purchase_order' },
    { label: 'Grant', value: 'grant' },
    { label: 'Scholarship', value: 'scholarship' },
    { label: 'Transfer of Credit', value: 'toc' },
    { label: 'Other', value: 'other' },
  ];

  const paymentPayerOptions = [
    { label: 'VA', value: 'va' },
    { label: 'Company', value: 'company' },
    { label: 'Student', value: 'student' },
    { label: 'IP', value: 'ip' },
  ];

  const { fundingSourceOptions } = useProfiles();

  const yesNoOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  return {
    fetchAccounts,
    tableColumnsList,
    tableColumnsStudentView,
    perPage,
    currentPage,
    totalAccounts,
    totalPageCount,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAccountListTable,

    resolveAccountProgramVariant,
    resolveAccountYNVariant,
    resolveAccountStatusVariant,

    refetchData,

    // Extra Filters
    createAtStartFilter,
    createAtEndFilter,

    //

    statusOptions,
    paymentTypeOptions,
    paymentStatusOptions,
    paymentMethodOptions,
    paymentPayerOptions,
    statusFilter,
    fundingSourceOptions,
    fundingFilter,
    yesNoOptions,
    balanceFilter,
    studentFilter,
    //
    paymentDateFilter,
  };
}
