<template>
  <span>
    <span v-if="tzTooltip" v-b-tooltip.hover :title="`Time In EST: ${defaultTimezoneTime}`">
      {{ formattedTime }}
    </span>
    <span v-else>
      {{ formattedTime }}
    </span>
  </span>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BTooltip, VBTooltip } from 'bootstrap-vue';
import { momentTzFormatted, defaultTimeZone, getDefaultTimeZone } from '@/libs/utils/time';

export default {
  name: 'FormattedTime',
  props: {
    timestamp: {
      type: [String, Number],
      required: true,
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
    timeZone: {
      type: String,
      default() {
        return getDefaultTimeZone();
      },
    },
    tzTooltip: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTooltip,
  },
  computed: {
    formattedTime() {
      return this.momentTzFormatted(this.timestamp, this.format, this.timeZone);
    },
    defaultTimezoneTime() {
      return this.momentTzFormatted(this.timestamp, this.format, defaultTimeZone);
    },
  },
  methods: {},
  setup() {
    const userData = ref(JSON.parse(localStorage.getItem('userData')) || {});

    return {
      momentTzFormatted,
      defaultTimeZone,
      getDefaultTimeZone,
    };
  },
};
</script>

<style scoped>
/* Your CSS here */
</style>
