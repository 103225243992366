<template>
  <div class="pl-50 mb-2">
    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">Communications</h3>
    </div>

    <!-- The Tabs -->
    <div class="d-flex align-items-center justify-content-between mb-75">
      <b-tabs nav-wrapper-class=" mb-0" nav-class="m-0" lazy>
        <b-tab title="Notes" @click="changeTab('notes')">
          <template #title>
            <font-awesome-icon icon="fa-regular fa-notes" />
            Notes
          </template>
        </b-tab>
        <b-tab title="Monday" @click="changeTab('monday')">
          <template #title> <font-awesome-icon icon="fa-kit fa-monday" size="xs" /> Monday </template>
        </b-tab>
      </b-tabs>

      <!--- Action Create -->
      <b-button v-if="showingTab === 'notes'" variant="primary" @click="openModal">
        <feather-icon icon="PlusIcon" />
        <span v-if="showingTab === 'notes'">Note</span>
      </b-button>
    </div>

    <!-- The Notes Tab Content -->
    <div v-if="showingTab === 'notes'">
      <student-note-item @refetch-data="refreshData" :student-data.sync="studentData" :profile-data.sync="profileData" ref="notesRef" />
      <div v-if="!hasNotes">No notes created for this student, yet.</div>
    </div>

    <!--- The Moday Tab Content-->
    <div v-if="showingTab === 'monday'">
      <student-monday-comments :student-data="studentData" :profile-data.sync="profileData" />
    </div>

    <!-- Modals to Create/Update/Delete -->
    <student-create-note-modal
      @refetch-data="refreshData"
      ref="crudNotes"
      v-show="showingTab === 'notes'"
      :createStudentNoteActive.sync="createStudentNoteActive"
      :student-data.sync="studentData"
      :profile-data.sync="profileData"
    />
  </div>
</template>

<script>
import { BCol, BCard, BImg, BMedia, BAvatar, BCardBody, BTabs, BTab, BButton, BNavItem, BModal, VBModal, BCardText } from 'bootstrap-vue';
import StudentNoteItem from './components/StudentNoteItem';
import StudentMondayComments from './components/StudentMondayComments';
import StudentCreateNoteModal from './components/StudentCreateNoteModal';
import store from '@/store';
import router from '@/router';

export default {
  components: {
    StudentCreateNoteModal,
    BCol,
    BCard,
    BCardBody,
    BModal,
    BTabs,
    BTab,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BNavItem,
    StudentNoteItem,
    StudentMondayComments,
    BCardText,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      showingTab: 'notes',
      createStudentNoteActive: false,
      studentDataList: this.studentData,
      isNotesEmpty: false,
    };
  },
  methods: {
    refreshData() {
      this.$emit('refetch-data');
    },
    changeTab(tab) {
      this.showingTab = tab;
    },
    openModal() {
      this.createStudentNoteActive = true;
    },
  },
  computed: {
    hasNotes() {
      return this.showingTab === 'notes' && this.profileData && this.profileData.notes && this.profileData.notes.length > 0;
    },
  },
  mounted() {},
};
</script>

<style></style>
