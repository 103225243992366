<template>
  <div>
    <b-modal
      id="modal-lg"
      ok-title="Add to Cohort"
      centered
      size="lg-wider"
      title="Place Roster Profile"
      ref="modal"
      @hidden="() => $emit('update:is-add-session-open', false)"
      :visible="isAddSessionOpen"
      :hide-footer="true"
      body-class="p-0"
    >
      <div v-if="profile">
        <b-col rows="12" class="mb-1">
          <div class="d-flex align-items-end justify-content-between px-75">
            <div>
              <div class="d-flex align-items-center mb-75" v-if="restrictedAvailable">
                <b-form-checkbox
                  @input="refreshTable"
                  class="custom-control-primary"
                  id="restricted-all"
                  v-model="restricted"
                  name="restricted-all"
                  switch
                >
                  <h4 class="mb-0">Filter From Preferred</h4>
                </b-form-checkbox>
              </div>
              <div v-if="!restricted" class="w-100">
                <b-tabs nav-wrapper-class=" mb-0" nav-class="m-0" v-model="placeInActive" lazy>
                  <b-tab title="Course Section">
                    <template #title>
                      <lucide-icon name="Book" style="width: 14px; height: 14px" />
                      <span class="d-none d-md-block"> Pathway Cohorts</span>
                      <span class="d-block d-md-none"> Pathways</span>
                    </template>
                  </b-tab>
                  <b-tab title="Pathway Cohort">
                    <template #title>
                      <lucide-icon name="Library" style="width: 14px; height: 14px" />
                      <span class="d-none d-md-block"> Course Sections</span>
                      <span class="d-block d-md-none"> Courses</span>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
              <div v-else>
                <div
                  v-if="profile.preferred && profile.preferred.pathway"
                  class="d-flex align-items-center text-nowrap"
                  style="gap: 0.625rem"
                >
                  <b-avatar
                    size="36"
                    :src="profile.preferred.pathway.avatar"
                    :text="avatarText(profile.preferred.pathway.name)"
                    :variant="`light-info`"
                    :to="{ name: 'apps-roster-pathway-view', params: { id: profile.preferred.pathway.id } }"
                    style="margin-top: 2px"
                    rounded
                  />
                  <div class="d-flex flex-column" style="gap: 0">
                    <b-link
                      :to="{ name: 'apps-roster-pathway-view', params: { id: profile.preferred.pathway.id } }"
                      class="font-weight-bold text-nowrap text-white mb-0"
                    >
                      {{ profile.preferred.pathway.name }}
                    </b-link>
                    <div class="d-flex align-items-center text-muted">
                      <lucide-icon name="Calendar" style="width: 14px; height: 14px; margin-right: 3px" />
                      <small v-if="profile.startDate">
                        {{ momentTz(profile.startDate).format('MMM YYYY') }}
                      </small>
                      <small v-else> No Preferred Date </small>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="profile.preferred && profile.preferred.course"
                  class="d-flex align-items-center text-nowrap"
                  style="gap: 0.625rem"
                >
                  <b-avatar
                    size="36"
                    :src="profile.preferred.course.avatar"
                    :text="avatarText(profile.preferred.course.name)"
                    :variant="`light-info`"
                    :to="{ name: 'apps-roster-course-view', params: { id: profile.preferred.course.id } }"
                    style="margin-top: 2px"
                    rounded
                  />
                  <div class="d-flex flex-column" style="gap: 0">
                    <b-link
                      :to="{ name: 'apps-roster-course-view', params: { id: profile.preferred.course.id } }"
                      class="font-weight-bold text-nowrap text-white mb-0"
                    >
                      {{ profile.preferred.course.name }}
                    </b-link>
                    <div class="d-flex align-items-center text-muted">
                      <lucide-icon name="Calendar" style="width: 14px; height: 14px; margin-right: 3px" />
                      <small v-if="profile.startDate">
                        {{ momentTz(profile.startDate).format('MMM YYYY') }}
                      </small>
                      <small v-else> No Preferred Date </small>
                    </div>
                  </div>
                </div>
                <div class="text-nowrap" v-else-if="profile.metadata && profile.metadata.certificate">
                  {{ profile.metadata.certificate }}
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <b-form-group class="justify-content-end mb-0">
                <div class="d-flex align-items-center text-nowrap">
                  <b-form-input v-model="searchTerm" placeholder="Search " type="text" class="d-inline-block" @input="handleSearch" />
                </div>
              </b-form-group>
            </div>
          </div>
        </b-col>

        <b-table
          id="table-test"
          ref="refSessionTable"
          class="mb-0"
          :items="placeInProvider"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchTerm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :responsive="true"
        >
          <template #cell(name)="data">
            <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="placeIn === 'pathway'">
              <b-avatar
                v-if="data.item.pathway"
                size="33"
                style="margin-top: 2px"
                :src="data.item.pathway.avatar"
                :text="avatarText(data.item.pathway.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small v-if="data.item.pathway"> {{ data.item.pathway.name }}</small>
                </b-link>
              </div>
            </div>
            <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="placeIn === 'course'">
              <b-avatar
                v-if="data.item.course"
                size="33"
                style="margin-top: 2px"
                :src="data.item.course.avatar"
                :text="avatarText(data.item.course.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small v-if="data.item.course"> {{ data.item.course.name }}</small>
                </b-link>
              </div>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="`${resolvePathwayCohortStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status.split('_').join(' ') }}
            </b-badge>
          </template>

          <!-- Column: Visibility
          <template #cell(visibility)="data">
            <b-badge :variant="`light-${resolvePathwayCohortVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
              {{ data.item.visibility.split('_').join(' ') }}
            </b-badge>
          </template> -->

          <!-- Column: Total Students -->
          <template #cell(profiles)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
                data.value ? `${data.value} Student${data.value !== 1 ? 's' : ''}` : 'No Students Enrolled'
              }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button
              @click="placeIn === 'pathway' ? assignPathwayCohort(data.item) : assignCourseSection(data.item)"
              variant="primary"
              size="sm"
              :disabled="checkAlreadyAssigned(data.item) && !$can('roster.permissive')"
              >{{ checkAlreadyAssigned(data.item) ? 'Already Enrolled' : 'Select' }}</b-button
            >
          </template>
        </b-table>

        <div class="mx-2 mb-75">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted">{{ totalResults }} Total Entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- <b-modal
      id="modal-lg"
      ok-title="Add to Session"
      centered
      size="lg"
      title="Input enrollment info"
      ref="modalStage2"
      @hidden="() => isAddProfileMetadataOpen = false"
      :visible="isAddProfileMetadataOpen"
      :hide-footer="false"
    >
      <b-card no-body>
        <b-row>

          <b-col cols="12" md="12">
            <b-form-group class="justify-content-end">
              <div class="d-flex align-items-center">
                <b-form-checkbox id="restricted-all" v-model="restricted" name="restricted-all" class="mr-3">
                  Restricted
                </b-form-checkbox>

                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                  @input="handleSearch"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col cols='12'>
            <pre>
              {{ JSON.stringify(metadata, null, 2) }}
            </pre>
          </b-col>


        </b-row>
      </b-card>
    </b-modal> -->

    <b-modal
      id="modal-lg"
      ok-title="Add to Session"
      centered
      size="lg"
      title="Additional Enrollment Info"
      ref="modalStage2"
      @hidden="() => (isAddProfileMetadataOpen = false)"
      :visible="isAddProfileMetadataOpen"
      :hide-footer="false"
      @ok="assignMetadata(selectedCohort)"
    >
      <div no-body>
        <b-row class="mb-1" v-if="false && metadata.toc.enabled">
          <b-col cols="12">
            <label id="toc-label">Transfer of Credit</label>
            <b-form-checkbox id="template-data-default" v-model="metadata.toc.enabled" switch />

            <b-tooltip title="Transfer of Credit" class="cursor-pointer" target="toc-label" />
          </b-col>

          <b-col cols="12" lg="4">
            <label for="toc-amount">Amount</label>
            <b-input-group prepend="$">
              <cleave
                id="toc-amount"
                v-model="metadata.toc.amount"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0"
              />
            </b-input-group>
          </b-col>

          <b-col cols="12" lg="4">
            <label for="toc-date-start">Start Date</label>
            <b-form-datepicker
              class="w-100"
              id="toc-date-start"
              :value="metadata.toc.startDate"
              @input="(val) => (metadata.toc.startDate = val)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <label for="toc-date-end">End Date</label>
            <b-form-datepicker
              class="w-100"
              id="toc-date-end"
              :value="metadata.toc.endDate"
              @input="(val) => (metadata.toc.endDate = val)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-col>

          <hr />
        </b-row>

        <!-- <b-row>
          <b-col>

            <b-form-group label='Reassignment Reason' label-for='transfer-reason'>
              <b-form-input id='transfer-reason' v-model='transferReason' autofocus
                :state='!!transferReason && transferReason.length > 0' trim placeholder='' />
            </b-form-group>

          </b-col>
        </b-row> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BFormCheckbox,
  BFormDatepicker,
  BCard,
  BCol,
  BRow,
  BButton,
  BTooltip,
  BTab,
  BTabs,
  BLink,
} from 'bootstrap-vue';

import Cleave from 'vue-cleave-component';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';

export default {
  components: {
    Cleave,
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BTooltip,
    BTab,
    BTabs,
    BLink,
  },
  data() {
    return {
      placeIn: 'pathway',
      placeInActive: null,

      searchTerm: '',
      perPage: 7,
      currentPage: 1,
      totalResults: 0,

      tableFields: [
        { key: 'name', sortable: true },
        {
          key: 'profiles',
          label: 'Students',
          formatter: (value) => {
            return value.length;
          },
        },
        // { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      restricted: true,
      restrictedAvailable: false,

      sortBy: 'startDate',
      sortDesc: true,

      selectedCohort: undefined,
      enrolledCohorts: [],

      metadata: { toc: { enabled: false } },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },

      isAddProfileMetadataOpen: false,
    };
  },
  props: {
    isAddSessionOpen: {
      type: Boolean,
    },
    profile: {
      type: Object,
    },
  },
  watch: {
    profile() {
      this.profileInit();
    },
    isAddSessionOpen() {
      this.profileInit();
    },
    placeIn(val) {
      this.refreshTable();
    },
    placeInActive(val) {
      this.placeIn = val === 1 ? 'course' : 'pathway';
    },
  },
  methods: {
    refreshTable() {
      console.log('REFRESH TABLE');
      this.$refs.refSessionTable.refresh();
    },
    profileInit() {
      // Reset
      this.restrictedAvailable = false;
      this.restricted = false;
      // this.selectedCohort = undefined;
      this.enrolledCohorts = [];

      // Set Restricted
      if (this.profile.preferred) {
        if (this.profile.preferred.pathway || this.profile.preferred.course) {
          this.restrictedAvailable = true;
          this.restricted = true;
        }
      }

      // Then Init
      this.getStudentCohorts();
    },
    placeInProvider(ctx) {
      const restrictedQp = {};
      if (this.restricted) {
        if (this.profile.preferred.pathway) {
          this.placeIn = 'pathway';
          this.placeInActive = 0;
          restrictedQp.pathway = this.profile.preferred.pathway.id;
          this.restricted = true;
        }

        if (this.profile.preferred.course) {
          console.log('SET THIS');
          this.placeIn = 'course';
          this.placeInActive = 1;
          restrictedQp.course = this.profile.preferred.course.id;
          this.restricted = true;
        }
      }

      const storeAction = this.placeIn === 'pathway' ? 'fetchPathwayCohortList' : 'fetchCourseSectionList';
      const promise = store.dispatch(`rosterStoreModule/${storeAction}`, {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'startDate'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
        populate: 'pathway,course,courseCohort',
        ...restrictedQp,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    getStudentCohorts() {
      if (!this.profile && !this.profile.student) {
        return (this.enrolledCohorts = []);
      }
      store
        .dispatch('rosterStoreModule/fetchProfiles', {
          page: 1,
          limit: 100,
          student: this.profile.student.id,
        })
        .then((response) => {
          const { results } = response.data;
          const cohortIds = results.map((result) => result.session?.id).filter((id) => id);
          this.enrolledCohorts = cohortIds;
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching profiles',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    async submitAssign() {
      if (!this.selectedCohort || !this.selectedCohort.id) {
        console.error('> no cohort selected!');
        return;
      }

      if (this.enrolledCohorts.includes(this.selectedCohort.id) && !this.$can('roster.permissive')) {
        const choice = this.placeIn === 'pathway' ? 'pathway cohort' : 'course section';
        return this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            variant: 'danger',
            title: `Failed to add to ${choice}`,
            text: `This student is already assigned to this ${choice}`,
          },
        });
      }

      const storeAction = this.placeIn === 'pathway' ? 'addStudentsToPathwayCohort' : 'addStudentsToCourseCohort';
      store
        .dispatch(`rosterStoreModule/${storeAction}`, {
          cohortId: this.selectedCohort.id,
          profileIds: [this.profile.id],
          metadata: this.metadata,
        })
        .then((response) => {
          const { data: session } = response;

          this.$emit('update:is-add-session-open', false);
          this.$emit('refresh', true);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added profile to session',
              text: 'This student has been assigned to this session',
            },
          });
        })
        .catch((err) => {
          console.error('failed to add profile to session', err);
        });
    },
    async assignMetadata(selectedCohort) {
      console.log('> assigning metadata', this.metadata, selectedCohort);
      // this.$emit('update:is-add-profile-metadata-open', false)

      await this.submitAssign();
      this.isAddProfileMetadataOpen = false;
    },
    checkAlreadyAssigned(item) {
      if (!item) return;
      if (this.placeIn === 'course') {
        if (!item.courseCohort) return false;
        return this.enrolledCohorts.includes(item.courseCohort.id);
      }
      if (this.placeIn === 'pathway') {
        if (!item.type === 'course') return false;
        return this.enrolledCohorts.includes(item.id);
      }
    },
    assignPathwayCohort(pathwayCohort) {
      console.log('> using pathway assign -', pathwayCohort);
      this.selectedCohort = { ...pathwayCohort };
      this.$emit('update:is-add-session-open', false);
      this.isAddProfileMetadataOpen = true;
    },
    assignCourseSection(section) {
      console.log('> course selection assign -', section);
      this.selectedCohort = { ...section.courseCohort };
      this.$emit('update:is-add-session-open', false);
      this.isAddProfileMetadataOpen = true;
    },
    selectProfile(profile) {
      this.profile = profile;
      this.stage = 'session';
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant, resolvePathwayCohortVisibilityVariant } = usePathwayCohorts();

    const toast = useToast();

    return {
      avatarText,
      toast,
      title,
      moment,
      resolvePathwayCohortName,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortVisibilityVariant,
    };
  },
};
</script>
