<template>
  <div>
    <!-- <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">Student Roster Profiles</h3>
    </div> -->

    <div class="d-flex justify-content-between align-items-center mb-75">
      <b-tabs nav-wrapper-class=" " nav-class="m-0" lazy>
        <b-tab title="Profiles" @click="changeTab('profiles')" v-if="$can('student.profile.list')">
          <template #title>
            <font-awesome-icon icon="fa-regular fa-address-card" />
            Profiles
          </template>
        </b-tab>
        <b-tab title="Sections" @click="changeTab('sections')" v-if="$can('student.section.list')">
          <template #title>
            <lucide-icon name="Book" style="width: 15px; height: 15px" />
            Sections
          </template>
        </b-tab>

        <b-tab title="Receivable" @click="changeTab('ar')" v-if="$can('accounting.receivable.account.list')">
          <template #title>
            <font-awesome-icon icon="fa-solid fa-dollar-sign" />
            Receivable
          </template>
        </b-tab>
      </b-tabs>
      <div>
        <b-button
          variant="primary"
          @click="createNewProfile"
          class="ml-1"
          style="line-height: 1rem; font-size: 1rem"
          v-if="$can('roster.profile.create')"
        >
          <feather-icon icon="PlusCircleIcon" size="16" class="mr-25" />
          <span class="text-nowrap">New Profile</span>
        </b-button>
      </div>
    </div>

    <div v-if="studentData && studentData.id">
      <student-relations-roster-profile-list
        v-if="showingTab === 'profiles' && $can('student.profile.list')"
        :student-data="studentData"
        ref="studentRosterProfileList"
        @focus-document="(event) => $emit('focus-document', event)"
      />
      <student-relations-section-list v-if="showingTab === 'sections' && $can('student.section.list')" :student-id="studentData.id" />
      <student-relations-ar-accounts-list
        v-if="showingTab === 'ar' && $can('accounting.receivable.account.list')"
        :student-id="studentData.id"
      />
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BTab, BTabs } from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';
import StudentRelationsRosterProfileList from '@/views/apps/student/students-view/relations/StudentRelationsRosterProfileList.vue';
import StudentRelationsSectionList from '@/views/apps/student/students-view/relations/StudentRelationsSectionList';
import StudentRelationsArAccountsList from '@/views/apps/student/students-view/relations/StudentRelationsArAccountsList';
import { ref } from '@vue/composition-api';
import ProfileListAddv2 from '../../roster/profile/create/ProfileListAddv2.vue';

export default {
  components: {
    StudentRelationsSectionList,
    StudentRelationsArAccountsList,
    StudentRelationsRosterProfileList,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BButton,
    ProfileListAddv2,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createNewProfile() {
      this.$refs.studentRosterProfileList.createNewProfile();
    },
    changeTab(tab) {
      this.showingTab = tab;
    },
  },
  setup(props) {
    const showingTab = ref('profiles');

    return {
      showingTab,
      title,
    };
  },
};
</script>

<style></style>
