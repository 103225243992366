<template>
  <b-modal
    id="modal-center"
    :visible="createStudentNoteActive"
    @hidden="closeModal"
    centered
    size="lg"
    ok-only
    ok-title="Create Note"
    @ok="doSaveChanges"
    header-class="mb-0 p-0"
    body-class="pb-0 pt-50 pl-75 pr-75"
    footer-class="pt-0 "
  >
    <template #modal-header>
      <div></div>
    </template>

    <div class="">
      <!-- <div class="d-flex">
        <div>
          <feather-icon icon="StudentIcon" size="19" />
          <h4 class="mb-0" style="padding-top: 5px">Create Notes</h4>
        </div>

        <div class="ml-auto mb-1">
          <b-button variant="primary" class="btn-sm" @click="repeatAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </div>
      </div> -->

      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeatAgain" class="">
        <h5 v-if="!unpreloadedLists || unpreloadedLists.length === 0" class="text-center">No notes listed</h5>

        <!-- Row Loop -->
        <div v-for="(item, index) in notes" :id="item.id" :key="item.id" ref="row" class="mb-1" v-if="item.preloaded === false">
          <!-- Item Name -->
          <b-col lg="12" md="12" sm="12" class="">
            <div class="d-flex align-items-center justify-content-between py-50">
              <div class="d-flex align-items-center">
                <!-- <b-button
                  variant="primary"
                  style="padding-left: 7px; padding-right: 6px; margin-right: 10px"
                  class="btn-sm"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="" />
                </b-button> -->
                <h4 class="mb-25 font-weight-bolder">New Student Note</h4>
              </div>
              <!-- <b-form-radio-group
                :id="`note-visibility-${index}`"
                v-model="item.visibility"
                :options="visibilityOptions"
                value-field="value"
                text-field="label"
              /> -->
            </div>
          </b-col>

          <!-- Description -->
          <b-col lg="12" md="12" sm="12" class="mb-50">
            <b-form-textarea
              :id="`note-description-${index}`"
              type="text"
              placeholder="Leave a note..."
              v-model="item.description"
              rows="5"
              max-rows="8"
              no-auto-shrink
            />
          </b-col>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '../../../../../store/student/studentStoreModule';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    ToastificationContent,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
    createStudentNoteActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    profileData() {
      this.reloadNotes();
    },
  },
  data: () => {
    return {
      submit: { sync: false },

      notes: [],
      nextTodoId: 1,
      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },
  computed: {
    modalTitle() {
      return "<i class='feather icon-file-plus'></i> Create Note";
    },
    unpreloadedLists() {
      return this.notes.filter((note) => note.preloaded === false);
    },
  },
  methods: {
    closeModal() {
      this.busy = false;
      this.$emit('update:create-student-note-active', false);
    },
    doSaveChanges() {
      const pwd = this.studentData;
      const id = pwd.id;

      const parsed = this.notes
        .filter((note) => note.description)
        .map((note) => {
          return {
            title: note.title || 'DEPRECATED',
            description: note.description,
            visibility: 'internal',
            author: (note.author && note.author.id ? note.author.id : undefined) || note.author || this.userData.id || undefined,
            _id: note._id,
            createdAt: note.createdAt ?? new Date(),
            updatedAt: note.updatedAt,
          };
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      store
        .dispatch('studentStoreModule/updateStudentProfile', {
          id,
          profileData: { notes: parsed },
        })
        .then((response) => {
          this.$emit('refetch-data');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    removeItem(index) {
      const note = this.notes[index];

      if (note === undefined) return;
      if (note.preloaded === true) return;

      this.notes.splice(index, 1);
    },
    reloadNotes() {
      const profile = this.profileData;
      if (!profile || !profile.notes) {
        return;
      }

      console.log(this.notes);
      this.notes = profile.notes
        .map((note) => {
          return { ...note, preloaded: true };
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      this.notes.push({
        preloaded: false,
        visibility: 'internal',
      });
    },
    repeatAgain() {
      this.notes.push({
        description: '',
        visibility: 'internal',
        preloaded: false,
      });
    },
  },
  mounted() {
    this.reloadNotes();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const visibilityOptions = [
      { label: 'Internal', value: 'internal' },
      { label: 'Student', value: 'student' },
    ];

    const { statusOptions } = useStudents();

    return {
      studentDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
