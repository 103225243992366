<template>
  <component is="div">
    <b-modal
      id="modal-toc"
      ok-title="Submit"
      centered
      size="md"
      :title="`Please select a course to ${isAssign ? 'assign credit for' : 'remove credit from'}`"
      ref="modal"
      @hidden="() => closeCredit()"
      :visible="isAddCreditOpen"
      @ok="() => submitProfileToC()"
      :ok-disabled="!tocEdit.section || (isAssign && (!tocEdit.note || tocEdit.note.length === 0))"
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Course" label-for="toc-course">
            <v-select v-model="tocEdit.section" :options="tocSectionOptions" :clearable="false" />
          </b-form-group>
        </b-col>

        <template v-if="tocEdit.override">
          <b-col cols="12">
            <label class="d-inline">Cost</label>
            <b-form-group prepend="$">
              <cleave id="toc-cost" v-model="tocEdit.cost" class="form-control" :raw="true" :options="options.cost" placeholder="0" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <label class="d-inline">Hours</label>
            <b-form-group prepend="$">
              <cleave id="toc-hours" v-model="tocEdit.hours" class="form-control" :raw="true" :options="options.hours" placeholder="0" />
            </b-form-group>
          </b-col>
        </template>

        <b-col cols="12" v-if="isAssign">
          <b-form-group label="Justification & Notes" label-for="toc-notes">
            <b-form-input
              v-if="tocEdit.noteCustom"
              id="toc-notes"
              v-model="tocEdit.note"
              autofocus
              :state="(isAssign && tocEdit.note.length > 0) || !isAssign"
              trim
              placeholder=""
            />
            <v-select v-else v-model="tocEdit.note" :options="tocJustificationOptions" :clearable="false" />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isAssign">
          <b-form-checkbox class="custom-control-primary float-right" v-model="tocEdit.override" name="toc-override" switch>
            Override cost & hours
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </component>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BRow,
  BTable,
} from 'bootstrap-vue';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import store from '@/store/index';
import { ref } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Cleave,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BDropdownItem,
    BDropdown,
    BCard,
    vSelect,
  },
  data() {
    return {
      tocEdit: {
        enabled: false,
        isModalOpen: false,
        isAssign: true,
        override: false,

        profile: undefined,
        section: undefined,
        note: '',
        noteCustom: false,
        cost: 0,
        hours: 0,
      },

      options: {
        cost: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        hours: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
        },
      },
    };
  },
  props: {
    isAddCreditOpen: {
      type: Boolean,
    },
    cohortData: {
      type: Object,
      default: () => {},
    },
    selectedProfile: {
      type: Object,
      default: () => {},
    },
    isAssign: {
      type: Boolean,
    },
  },
  emits: ['refresh', 'update:is-add-credit-open'],
  watch: {
    'tocEdit.note'(val) {
      if (val === 'Other') this.tocEdit.noteCustom = true;
    },
  },
  methods: {
    closeCredit() {
      this.tocEdit.isModalOpen = false;
      this.$emit('update:is-add-credit-open', false);
      this.$emit('refresh');
    },
    submitProfileToC() {
      const { isAssign, selectedProfile: profile } = this;

      const sectionId = this.tocEdit.section.value;
      profile.sections[sectionId].toc = isAssign
        ? {
            enabled: true,
            notes: this.tocEdit.note,
            cost: this.tocEdit.cost,
            hours: this.tocEdit.hours,
          }
        : { enabled: false };

      console.log(this.tocEdit, isAssign, profile.sections[sectionId]);

      (isAssign
        ? store.dispatch('rosterStoreModule/assignCourseCredit', {
            id: profile.id,
            creditData: {
              enabled: true,
              notes: this.tocEdit.note,
              sectionId,
              ...(this.tocEdit.override
                ? {
                    cost: this.tocEdit.cost,
                    hours: this.tocEdit.hours,
                  }
                : {}),
            },
          })
        : store.dispatch('rosterStoreModule/unassignCourseCredit', {
            id: profile.id,
            creditData: { enabled: false, sectionId },
          })
      )
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Credit assignment edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.tocEdit.note = '';
          this.$emit('refresh');
        })
        .catch((error) => {
          this.$emit('refresh');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  computed: {
    tocSectionOptions() {
      console.log('> computing sec options for cohort', {
        assign: this.isAssign,
        cohort: this.cohortData,
        profile: this.selectedProfile,
        open: this.isAddCreditOpen,
      });
      return this.cohortData.sections
        .filter((section) => {
          console.log('running filter on sec', section);
          if (this.cohortData.sections && this.selectedProfile.sections[section.id]) {
            console.log(`> toc enabled ${section.id}`, this.selectedProfile.sections[section.id].toc?.enabled);
            return this.isAssign
              ? !this.selectedProfile.sections[section.id].toc?.enabled
              : this.selectedProfile.sections[section.id]?.toc?.enabled;
          }
        })
        .map((section) => {
          // check if the student has been assigned to another section, if so, credit the replaced section rather than the cohort-based one.
          const replacedById = this.selectedProfile.sections[section.id]?.replacedBy;
          return { label: section.name, value: replacedById || section.id };
        });
    },
  },
  mounted() {
    this.tocEdit.notes = this.tocSectionOptions.length > 0 ? this.tocSectionOptions[0] : undefined;
  },
  setup({ props }) {
    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant, resolvePathwayCohortVisibilityVariant } = usePathwayCohorts();

    const { resolveCourseSectionName } = useCourseSections();

    const toast = useToast();

    const tocJustificationOptions = ref([
      'Certification Earned <12 mo & Academic Experience',
      'Active Certification Earned<12 mo & Work Experience',
      'Active Certification w/ 10+ yrs Work Experience',
      'IP Alumni w/ Active Certification Earned <12 mo',
      'IP Alumni w/ Active Certification & 10+ yrs Work Experience',
      'Other',
    ]);
    return {
      toast,
      title,
      moment,
      avatarText,
      resolvePathwayCohortName,
      resolveCourseSectionName,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortVisibilityVariant,

      tocJustificationOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
